import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import WorkoutTimerIcon from "../images/WorkoutTimerIcon.png"

const WTPrivacyPolicyEN = () => (
    <Layout>
        <Seo title="Application" />
        <div className="main">
            <section className="contents">
				<h3>プライバシーポリシー</h3>
				<p>In distributing WorkoutTimer from AppStore operated by Apple, we will recognize the importance of protecting personal information and promote thorough protection of personal information.</p>
				<img className="padding-top_10vh padding-bottom_10vh" src={WorkoutTimerIcon} alt="Workout Timer Icon" width="150"/>

				<h3>Purpose of use of personal information</h3>
				<p>We may contact you with personal information during support. We will not use it for purposes other than sending e-mail or materials as a response to your contact or question.</p>
				
				<h3>Safety of personal information</h3>
				<p>Security measures are taken to ensure the safety of personal information.</p>
				
				<h3>Eligibility for use</h3>
				<p>This application is available if you agree to the privacy policy.<br />
				If you do not agree or withdraw the agreement, please stop the use of this application and delete it.</p>
				
				<h3>Disclaimer</h3>
				<p>Please note that even if the user suffers any disadvantage or damage by using this application, the provider does not take any responsibility.</p>
				
				<h3>Inquiries</h3>
				<p>For inquiries regarding handling of personal information, please contact below.<br />
				For security reasons, emails with a virus found when checking on the server side are automatically deleted.</p>
				<p><a href="https://blog.ushiya.net/このブログについて/#toc6" style={{color:'#b13223'}}>Contact Form</a>（Link to Contact Form of my blog）</p>
				
				<h3>Privacy policy changes</h3>
				<p>If we deem it necessary, we can change the privacy policy at any time without prior notice.</p>

				<p>NOTICE: This document is translated with Google Transfer. The base document is <a href="http://www.ushiya.net/Apps/WorkoutTimerPrivacyPolicy.html">here</a>.</p>
				<p>更新日：2019.8.8</p>
            </section>
        </div>
    </Layout>
  )
  
  export default WTPrivacyPolicyEN